import { FC, KeyboardEvent, useState } from 'react';
import {
  GetHandleProps,
  GetTrackProps,
  Handles,
  Rail,
  Slider,
  SliderItem,
  Ticks,
  Tracks,
} from 'react-compound-slider';

import globalConfiguration from '../../configuration/globalConfiguration';
import { ILanguageService } from '../../interfaces/ILanguageService';
import { TYPES } from '../../types';

const languageService = globalConfiguration.container.get<ILanguageService>(TYPES.ILanguageService);

const Handle: FC<{
  disabled: boolean;
  handle: SliderItem;
  isSelected: boolean;
  label: string;
  getHandleProps: GetHandleProps;
}> = ({ disabled, handle, isSelected, label, getHandleProps }) => (
  <div
    tabIndex="-1" // Required to be available for keyboard interactions.
    className="smhc-handle"
    style={{
      left: `${handle.percent}%`,
      backgroundColor: disabled ? '#D8E0F3' : undefined,
    }}
    {...getHandleProps(handle.id)}
  >
    <div
      className="smhc-dot"
      style={{
        pointerEvents: 'none', // Prevent 'dot' div to be clickable as otherwise the issues occur with keyboard interactions.
        backgroundColor: isSelected ? '#451AFF' : undefined,
      }}
    />
    <div
      className="smhc-label"
      style={{
        backgroundColor: disabled ? '#8B91A2' : undefined,
      }}
    >
      {languageService.instant(`AC.GROUP.SETTINGS.${label}`)}
    </div>
  </div>
);

const Track: FC<{
  disabled: boolean;
  source: SliderItem;
  target: SliderItem;
  getTrackProps: GetTrackProps;
}> = ({ disabled, source, target, getTrackProps }) => (
  <div
    className="smhc-track"
    style={{
      left: `${source.percent}%`,
      width: `${target.percent - source.percent}%`,
      backgroundColor: disabled ? '#8B91A2' : undefined,
    }}
    {...getTrackProps()} /* this will set up events if you want it to be clickeable (optional) */
  />
);

const Tick: FC<{ count: number; tick: SliderItem }> = ({ count, tick }) => (
  <div>
    <div
      className="smhc-tick1"
      style={{
        left: `${tick.percent}%`,
      }}
    />
    <div
      className="smhc-tick2"
      style={{
        marginLeft: `${-(100 / count) / 2}%`,
        width: `${100 / count}%`,
        left: `${tick.percent}%`,
      }}
    >
      {tick.value}
    </div>
  </div>
);

const ThreeSlides: FC<{
  disabled: boolean;
  initial: number[];
  scope: { resetSlider: (newValues: number[]) => void };
  onChange: (values: number[]) => void;
}> = ({ disabled, initial, scope, onChange }) => {
  const [selectedHandleIndex, setSelectedHandleIndex] = useState(-1);
  const [values, setValues] = useState(initial);

  const domain = [0, 100];
  const step = 1;
  const labels = ['PWP', 'FC', 'SP'];

  const handleChangeValues = (values: readonly number[]): void => {
    setValues(values as number[]);
    onChange(values as number[]);
  };

  const handleBlur = (): void => {
    setSelectedHandleIndex(-1);
  };

  const handleClick = (e: MouseEvent, idx: number) => {
    setSelectedHandleIndex(idx);
  };

  const handleKeyDown = (e: KeyboardEvent): void => {
    const currentValue = values[selectedHandleIndex];
    const minValue =
      selectedHandleIndex === 0 ? domain[0] : selectedHandleIndex === 1 ? values[0] : values[1];
    const maxValue =
      selectedHandleIndex === 2 ? domain[1] : selectedHandleIndex === 1 ? values[2] : values[1];

    let newValue = currentValue;

    switch (e.key) {
      case 'ArrowRight':
      case 'ArrowUp': {
        const attemptedValue = currentValue + step;

        newValue = Math.min(attemptedValue < maxValue ? attemptedValue : currentValue, maxValue);

        break;
      }

      case 'ArrowLeft':
      case 'ArrowDown': {
        const attemptedValue = currentValue - step;

        newValue = Math.max(attemptedValue > minValue ? attemptedValue : currentValue, minValue);

        break;
      }

      default:
        break;
    }

    const getValues = () => {
      return [0, 1, 2].map((x) => {
        if (x === selectedHandleIndex) {
          return newValue;
        }

        return values[x];
      });
    };

    handleChangeValues(getValues());
  };

  // Could reset to initial values, but if save has happened, initial values may no longer be correct
  const reset = (newValues: number[]) => {
    setValues(newValues);
  };

  // eslint-disable-next-line no-param-reassign, no-param-reassign
  scope.resetSlider = reset;

  return (
    <Slider
      disabled={disabled}
      domain={domain}
      mode={2}
      step={1}
      values={values}
      onUpdate={handleChangeValues}
      className="smhc-slider"
    >
      <Rail>
        {({ getRailProps }) => (
          <div
            disabled={disabled}
            {...getRailProps()}
            className="smhc-rail"
          />
        )}
      </Rail>

      <Handles>
        {({ handles, getHandleProps }) => (
          <div className="slider-handles">
            {handles.map((handle, idx) => (
              <Handle
                disabled={disabled}
                getHandleProps={() =>
                  getHandleProps(handle.id, {
                    onBlur: handleBlur,
                    onClick: (e: MouseEvent) => handleClick(e, idx),
                    onKeyDown: handleKeyDown,
                  })
                }
                handle={handle}
                isSelected={idx === selectedHandleIndex}
                key={handle.id}
                label={labels[idx]}
              />
            ))}
          </div>
        )}
      </Handles>

      <Tracks
        left={false}
        right={false}
      >
        {({ tracks, getTrackProps }) => (
          <div className="slider-tracks">
            {' '}
            {tracks.map(({ id, source, target }) => (
              <Track
                disabled={disabled}
                getTrackProps={getTrackProps}
                key={id}
                source={source}
                target={target}
              />
            ))}
          </div>
        )}
      </Tracks>

      <Ticks count={15}>
        {({ ticks }) => (
          <div className="slider-ticks">
            {ticks.map((tick) => (
              <Tick
                count={ticks.length}
                key={tick.id}
                tick={tick}
              />
            ))}
          </div>
        )}
      </Ticks>
    </Slider>
  );
};

export default ThreeSlides;
