import { Container } from 'inversify';

interface GlobalConfiguration {
  container: Container;
}

const globalConfiguration: GlobalConfiguration = {
  container: null as unknown as Container,
};

export default globalConfiguration;
